<template>
  <main class="project-log-sidenav">
    <section class="project-log-sidenav__side">
      <div class="project-log-sidenav__header">
        <div class="project-log-sidenav__title">
          <h4>Audit <strong> Logs</strong></h4>
          <v-icon
            icon="mdi-close"
            class="tw-cursor-pointer"
            @click="closeAuditModal"
          ></v-icon>
        </div>
        <div class="audit__logs">
          <div class="confirm_date">
            <DatePicker
              v-model.range="dataRange"
              class="date__selector"
              :popover="true"
            >
              <template #default="{ togglePopover, inputValue, inputEvents }">
                <div class="calendar__select" @click="() => togglePopover()">
                  <v-text-field
                    :value="
                      inputValue.start
                        ? `${inputValue.start}
            ${inputValue.end}`
                        : ''
                    "
                    v-on="inputEvents"
                    variant="outlined"
                    placeholder="Choose Date"
                    class="date__start c-input"
                    prepend-icon="mdi-calendar-text"
                    color="#0C0F4A"
                  />
                </div>
              </template>
            </DatePicker>
            <v-btn
              prepend-icon="mdi-tray-arrow-down"
              class="button button-lightGray"
              size="large"
              @click="downloadAuditLogs"
            >
              Download XLS
            </v-btn>
          </div>
          <v-text-field
            placeholder="Search"
            class="search_field"
            bg-color="#fff"
            variant="outlined"
            v-model="searchQuery"
            @input="debouncedSearch"
          >
            <template v-slot:prepend-inner>
              <img src="@/assets/icons/trade-network-search.svg" />
            </template>
          </v-text-field>

          <div v-if="Object.keys(groupedAuditLogs).length">
            <div
              v-for="(logs, date) in groupedAuditLogs"
              :key="date"
              class="overview__detail__wrapper"
            >
              <div class="overview__detail">
                <div class="overview__detail__date">
                  <h6>{{ formatDateWithMoment(date) }}</h6>
                </div>
                <div class="overview__detail__item__wrapper">
                  <div
                    v-for="log in logs"
                    :key="log.id"
                    class="overview__detail__item"
                  >
                    <div class="overview__detail__item__list">
                      <!-- <v-icon v-if="log.iconName">
                        <img
                          :src="`@/assets/icons/${log.iconName}.svg`"
                          :alt="log.iconName"
                        />
                      </v-icon> -->

                      <div class="overview__detail__icon">
                        <v-icon>
                          <img src="@/assets/icons/pad-pen.svg" alt="" />
                        </v-icon>
                      </div>
                    </div>
                    <div class="overview__detail__content">
                      <div class="overview__detail__text">
                        <p v-html="log.content"></p>
                      </div>
                      <div class="overview__detail__time">
                        <p>{{ formatDateWithTime(log.dateCreated) }}</p>
                        <v-icon
                          color="#FAA500"
                          icon="mdi-chevron-right-circle"
                        ></v-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="no-logs-message">
            <p>No logs available</p>
          </div>
        </div>
      </div>
    </section>
    <section class="project-log-sidenav__overlay tw-hidden"></section>
  </main>
</template>

<script lang="ts" setup>
import { computed, onMounted, watch, ref } from "vue";
import moment from "moment";
import { debounce } from "lodash";
import { DatePicker } from "v-calendar";
import * as XLSX from "xlsx";
import store from "@/store";
import { AUDIT_LOG_STORE } from "@/store/modules/audit-logs";
import { JOBS_STORE } from "@/store/modules/jobs";
import { PROJECT_STORE } from "@/store/modules/project";
import { UserRolesEnum } from "../enums/RolesEnum";
import { USER_STORE } from "@/store/modules/user";

const searchQuery = ref("");
const emits = defineEmits(["closeModal"]);
const dataRange = ref();
const projectId = ref();

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const currentJobDetails = computed(
  () => store.getters[`${JOBS_STORE}/getJobDetails`]
);
const projectJobdetails = computed(
  () => store.getters[`${PROJECT_STORE}/projectDetails`]
);
const auditLogs_all = computed(
  () => store.getters[`${AUDIT_LOG_STORE}/getAuditLogsList_all`]
);

const groupedAuditLogs: any = computed(() => {
  return auditLogs_all.value.reduce((groups: any, log: any) => {
    const date = log?.dateCreated?.split("T")[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(log);
    return groups;
  }, {});
});

const closeAuditModal = () => {
  emits("closeModal");
};

const formatDateWithMoment = (date: any) => moment(date).format("DD-MM-YYYY");
const formatDateWithTime = (date: any) => moment(date).format("hh:mm a");

const searchApi = async (
  query: any,
  startDate: any = "",
  endDate: any = ""
) => {
  let queryParams = query?.trim();
  await store.dispatch(`${AUDIT_LOG_STORE}/getAuditLogs_all`, {
    search: queryParams,
    startDate,
    endDate,
    projectId,
  });
};

const downloadAuditLogs = () => {
  const wb = XLSX.utils.book_new();
  const ws_name = "Audit Logs";
  const ws_data = [["Date", "Content", "Time"]];

  if (auditLogs_all.value && typeof auditLogs_all.value === "object") {
    Object.entries(auditLogs_all.value).forEach(([date, group]) => {
      const logs = Array.isArray(group) ? group : [group];
      logs.forEach((log) => {
        if (log && typeof log === "object") {
          const content = log.content || "No content";
          const time = formatDateWithTime(log.dateCreated) || "No time";
          const formattedDate = formatDateWithMoment(log.dateCreated) || date;
          ws_data.push([formattedDate, content, time]);
        }
      });
    });
  } else {
    console.error("Invalid auditLogs_all.value structure or missing data");
  }

  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  XLSX.utils.book_append_sheet(wb, ws, ws_name);
  XLSX.writeFile(wb, "Audit-log.xlsx");
};

const debouncedSearch = debounce(() => {
  searchApi(searchQuery.value);
}, 1000);

watch(dataRange, (newValue, oldValue) => {
  if (newValue && newValue.start && newValue.end) {
    filterAuditLogsByDate(newValue.start, newValue.end);
  }
});
const filterAuditLogsByDate = (startDate: any, endDate: any) => {
  searchApi(
    searchQuery.value,
    moment(startDate).format("YYYY-MM-DD"),
    moment(endDate).format("YYYY-MM-DD")
  );
};

onMounted(async () => {
  if (user.value.role.name === UserRolesEnum.PROPERTY_OWNER) {
    projectId.value = projectJobdetails?.value?.id;
  } else projectId.value = currentJobDetails?.value?.id;

  await store.dispatch(`${AUDIT_LOG_STORE}/getAuditLogs_all`, {
    search: "",
    starDate: "",
    endDate: "",
    projectId: projectId.value?.toString(),
  });
});
</script>

<style lang="scss" scoped>
.no-logs-message {
  text-align: center;
  font-size: 16px;
  color: #888;
  padding: 20px;
}
.project-log-sidenav {
  .project-log-sidenav__side {
    .project-log-sidenav__header {
      width: 100%;
      max-width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      .project-log-sidenav__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba($cardBorder, 1);
        padding: 40px 40px 28px;
        h4 {
          @include fluidFont(24, 18, 1.3);
          font-weight: 400;
          color: rgba($blueDark, 1);
        }
        .v-icon {
          @include fluidFont(14, 14, 1);
          color: rgba($blueDark, 1);
        }
      }
      .audit__logs {
        padding: 22px;
        @include respond(s720) {
          padding: 22px 24px;
        }
        .confirm_date {
          display: flex;
          gap: 12px;
          align-items: center;
          margin-bottom: 16px;
          .calendar__select {
            min-width: 300px;
            @include respond(sm) {
              min-width: 190px;
            }
            .date__start {
              padding: 7px 16px;
              margin: 0;
              border: 1px solid rgba($buttonText, 0.4);
              border-radius: 8px;
              :deep(.v-input__prepend) {
                .v-icon {
                  cursor: pointer;
                }
              }
              :deep(.v-input__control) {
                .v-field {
                  .v-field__field {
                    align-items: center;
                    .v-field__input {
                      background-color: transparent;
                      padding: 0;
                      min-height: auto;
                      @include fluidFont(12, 12, 1.3);
                      font-weight: 400;
                      letter-spacing: 0.4px;
                      color: rgba($blueDark, 1);
                    }
                    ::placeholder {
                      @include fluidFont(12, 12, 1.3);
                      font-weight: 400;
                      letter-spacing: 0.4px;
                      color: rgba($blueDark, 1);
                    }
                  }
                  .v-field__outline {
                    display: none;
                  }
                }
              }
            }
          }
          .button-lightGray {
            display: flex;
            align-items: center;
            width: 144px;
            max-width: 100%;
            @include respond(xs) {
              width: 125px;
            }
            :deep(.v-btn__content) {
              text-transform: capitalize;
              @include fluidFont(12, 12, 1.2);
              font-weight: 600;
              color: rgba($buttonText, 1);
            }
            :deep(.v-btn__prepend) {
              .v-icon {
                @include fluidFont(16, 16, 1.2);
              }
            }
          }
        }
        .search_field {
          :deep(.v-input__control) {
            .v-field {
              border-radius: 8px;
              padding: 0 16px;
              gap: 8px;
              .v-field__field {
                .v-field__input {
                  padding: 0;
                  min-height: 40px;
                  @include fluidFont(12, 12, 1.3);
                  font-weight: 400;
                  letter-spacing: 0.4px;
                  color: rgba($blueDark, 1);
                }
                ::placeholder {
                  @include fluidFont(12, 12, 1.3);
                  font-weight: 400;
                  letter-spacing: 0.4px;
                  color: rgba($blueDark, 1);
                }
              }
            }
          }
        }
        .overview__detail__wrapper {
          margin-top: 16px;
          height: 100%;
          max-height: 670px;
          overflow: auto;
          @include respond(xlg) {
            max-height: 460px;
          }
          @include respond(xs) {
            max-height: 340px;
          }
          .overview__detail {
            display: flex;
            align-items: flex-start;
            gap: 16px;
            @include respond(s720) {
              flex-direction: column;
            }
            .overview__detail__date {
              width: 100px;
              max-width: 100%;
              margin-top: 5px;
              @include respond(s720) {
                margin: 0 0 0 45px;
              }
              h6 {
                @include fluidFont(14, 14, 1.2);
                font-weight: 500;
                color: rgba($blueDark, 1);
                white-space: nowrap;
              }
            }
            .overview__detail__item__wrapper {
              width: calc(100% - 115px);
              max-width: 100%;
              @include respond(s720) {
                width: 100%;
              }
              .overview__detail__item {
                text-align: left;
                display: flex;
                align-items: flex-start;
                gap: 20px;
                .overview__detail__icon {
                  width: 32px;
                  height: 32px;
                  background-color: rgba($PrimaryPurple, 0.1);
                  border-radius: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;
                  .v-icon {
                  }
                  ::after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    right: 0;
                    top: 100%;
                    border-left: 2px dashed #dde2e7;
                    height: 90px;
                    z-index: 0;
                  }
                }
                .overview__detail__icon__trade-passport {
                  width: 32px;
                  height: 32px;
                  background-color: rgba(240, 243, 232, 1);
                  border-radius: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: relative;
                  .v-icon {
                  }
                  ::after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    right: 0;
                    top: 100%;
                    border-left: 2px dashed #dde2e7;
                    height: 90px;
                    z-index: 0;
                  }
                }
                .overview__detail__content {
                  padding: 16px;
                  border-radius: 8px;
                  background-color: #f3f3f6;
                  width: calc(100% - 50px);
                  max-width: 100%;
                  margin-bottom: 16px;
                  .overview__detail__text {
                    p {
                      @include fluidFont(14, 14, 1.4);
                      font-weight: 400;
                      color: rgba($blueDark, 1);
                    }
                  }
                  .overview__detail__time {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 10px;
                    p {
                      @include fluidFont(11, 11, 1.4);
                      font-weight: 400;
                      color: rgba($blueDark, 0.6);
                    }
                    .v-icon {
                      @include fluidFont(16, 16, 1);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.project-log-sidenav {
  display: flex;
  max-width: 100%;
  z-index: 999;
  &__side {
    // position: fixed;
    // max-width: 535px;
    // width: 100%;
    // top: 0;
    // bottom: 0;
    // height: 100vh;
    // background: #fff;
    // box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
    // transition: right 0.3s ease-in-out;
    // z-index: 1;
  }
  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(197, 210, 242, 0.5);
    left: 0;
    top: 0;
    z-index: -1;
  }
}

// :deep(.v-navigation-drawer__scrim) {
//   position: fixed !important;
// }
</style>
